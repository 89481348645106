import React from "react"
import { connect, useSelector } from "react-redux"
import { WhiteLabelQuery } from "../../prismic/staticQueries"
import Layout from "../../components/layout"
import { Link } from "gatsby"

import {
  SubscriptionPanel,
  MainContent,
  BreadCrumbBanner,
} from "../../components/shared"
const WhiteLabel = () => {
  const language = useSelector(state => state.language)
  const whiteLabelData = WhiteLabelQuery(language)
  const {
    page_heading,
    banner_image,
    description_content,
    button_label,
    button_link,
    button_color,
    body_image,
  } = whiteLabelData
  return (
    <Layout>
      <BreadCrumbBanner banner_image={banner_image} heading={page_heading} />
      <div className="mt-5">
        <MainContent
          description_heading={page_heading}
          description_content={description_content}
        >
          <div className="d-flex flex-column align-items-center">
            <img src={body_image.url} alt="" />
            <br />
            <Link
              className="btn"
              to={button_link[0].text}
              style={{ backgroundColor: button_color, color: "#fff" }}
            >
              {" "}
              {button_label[0].text}
            </Link>
          </div>
        </MainContent>
      </div>
      <SubscriptionPanel />
    </Layout>
  )
}

const mapStateToProps = state => ({ language: state.language })

export default connect(mapStateToProps)(WhiteLabel)
